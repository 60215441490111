@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Satoshi';
  src: url('../assets/font/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../assets/font/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../assets/font/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../assets/font/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../assets/font/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../assets/font/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GamjaFlower';
  src: url('../assets/font/GamjaFlower-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PoorStory';
  src: url('../assets//font/PoorStory-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  background-image: "url(/assets/Texture.png)" !important;
  background-size: "cover";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
