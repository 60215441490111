@import "./index.css";

.App {
  text-align: center;
  background-color: #FFFDFB;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --cards: 4;
  --cardHeight: 620px;
  --cardTopPadding: 1.5em;
}

#cards {
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--cards), var(--cardHeight));
  gap: var(--cardMargin);
  padding-bottom: calc(var(--cards) * var(--cardTopPadding));
  margin-bottom: var(--cardMargin);
}

#card1 {
  --index: 1;
}
#card2 {
  --index: 2;
}
#card3 {
  --index: 3;
}
#card4 {
  --index: 4;
}

.card {
  position: sticky;
  top: 0;
  padding-top: calc(var(--index) * var(--cardTopPadding));
}

#card1 .card-body {
  background-color: #FFDAD4;
  margin-right: 10px;
  margin-left: 10px;
}
#card2 .card-body {
  background-color: #B3D0F3;
  margin-right: 10px;
  margin-left: 10px;
}
#card3 .card-body {
  background-color: #DCEDC8;
  margin-right: 10px;
  margin-left: 10px;
}
#card4 .card-body {
  background-color: #F5F7FF;
  margin-right: 10px;
  margin-left: 10px;
}

.card-body {
  box-sizing: border-box;
  border-radius: 50px;
  height: var(--cardHeight);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}
